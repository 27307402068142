import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Home from './pages';
import Download from './pages/download';
import Nav from './component/navbar/navbar.js'
import { createBrowserHistory } from "history";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

const hist = createBrowserHistory();

// const themeLight = createTheme({
// 	palette: {
// 		background: {
// 			default: "#e4f0e2"
// 		}
// 	}
// });

// const themeDark = createTheme({
// 	palette: {
// 		background: {
// 			default: "#222222"
// 		},
// 		// text: {
// 		// 	primary: "#ffffff",
// 		// 	fontFamily: `sans-serif`,
// 		// }
// 	}
// });

function App() {
	const [light, setLight] = React.useState(true);

	return (
		<Router>
			{/* <ThemeProvider theme={!light ? themeLight : themeDark}> */}
				{/* <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button> */}
				<Nav />
				<Routes history={hist}>
					<Route exact path='/' element={<Home />} />
					<Route path='/pm25' element={<Home />} />
					<Route path='/download' element={<Download />} />
					<Route path="*" element={<Navigate replace to="/" />} />
				</Routes>
			{/* </ThemeProvider> */}
		</Router>
	);
}

export default App;
