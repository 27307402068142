import React, { useRef, useState, useEffect } from 'react';

import '../App.css';

import { MapContainer, TileLayer, WMSTileLayer, LayersControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';


import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { makeStyles } from '@mui/styles';
import { styled, createTheme, alpha } from '@mui/material/styles';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {
    Box,
    Stack,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    CircularProgress,
    Skeleton,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Fab,
    Tooltip,
    FormControlLabel,
    Switch,
    colors,
    // getIconButtonUtilityClass
} from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LayersIcon from '@mui/icons-material/Layers';
import MapIcon from '@mui/icons-material/Map';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import gistdaPic from '../imgs/gistda_logo.png';
import kuPic from '../imgs/ku.png';
import mhesiPic from '../imgs/mhesi.png';
import nrctPic from '../imgs/nrct.png';
import pcdPic from '../imgs/pcd.png';
import ccdcPic from '../imgs/ccdc.png';
import andPic from '../imgs/android.png';
import aaplPic from '../imgs/aapl.png';
import PMUBPic from '../imgs/PMUB-01.png';
import InfoIcon from '@mui/icons-material/Info';

import { Await } from 'react-router-dom';

import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import TablePagination from '@mui/material/TablePagination';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
// import Button from "@mui/material/Button";

let sphere = null;
let map = null;

const Home = () => {

    const [light, setLight] = React.useState(false);

    const themeLight = createTheme({
        palette: {
            background: {
                default: "#e4f0e2"
            }
        }
    });

    const themeDark = createTheme({
        palette: {
            background: {
                default: "#222222"
            },
            text: {
                primary: "#ffffff",
                fontFamily: `sans-serif`,
            }
        }
    });

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.screen.width > 700 ? 700 : 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '25px',
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // const [dateTime, setDateTime] = useState('');

    const [urlHotspot, setUrlHotspot] = useState('https://gistdaportal.gistda.or.th/data/services/pm_check/hotspot_pmcheck/MapServer/WMSServer');
    const [urlPM25, setUrlPM25] = useState('https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/pm25_hourly_raster/wms');
    const [urlPM2524, setUrlPM2524] = useState('https://service-proxy-765rkyfg3q-as.a.run.app/api_geoserver/geoserver/pm25_hourly_raster_24hr/wms');
    const [urlProvince, setUrlProvince] = useState('https://gistdaportal.gistda.or.th/data2/services/L05_Province_GISTDA_50k_nonlabel/MapServer/WMSServer');
    const [allData, setAllData] = useState([]);
    const [allTime, setAllTime] = useState([]);
    const [basemap, setBasemap] = useState([]);
    const [sBasemap, setsBasemap] = useState([]);
    const [cData, setcData] = useState([null, null]);

    const [open, setOpen] = useState(false);
    const [openX, setOpenX] = useState(false);
    const [openI, setOpenI] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [openA, setOpenA] = useState(false);
    const [openT, setOpenT] = useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseX = () => setOpenX(false);
    const handleCloseI = () => setOpenI(false);
    const handleClose1 = () => setOpen1(false);
    const handleCloseA = () => setOpenA(false);
    const handleCloseT = () => setOpenT(false);

    const [modalName, setModalName] = useState([]);
    const [modalNameA, setModalNameA] = useState([]);
    const [modalNameT, setModalNameT] = useState([]);

    const [lastDt, setLastDt] = useState([]);
    const [predDt, setPredDt] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchedDataLoc, setFetchedDataLoc] = useState([]);
    const [isLoadingLoc, setIsLoadingLoc] = useState(false);
    const [fetchedDataAmphoe, setFetchedDataAmphoe] = useState([]);
    const [isLoadingAmphoe, setIsLoadingAmphoe] = useState(false);
    const [fetchedDataTambon, setFetchedDataTambon] = useState([]);
    const [isLoadingTambon, setIsLoadingTambon] = useState(false);
    const [fetchedData24, setFetchedData24] = useState([]);
    const [isLoading24, setIsLoading24] = useState(false);
    const [fetchedData24Amphoe, setFetchedData24Amphoe] = useState([]);
    const [isLoadingMap, setIsLoadingMap] = useState(false);

    const [openHotspot, setOpenHotspot] = useState(true);
    const [openProvince, setOpenProvince] = useState(true);

    const [expanded, setExpanded] = useState([]);

    // let now_pv_id = '00';

    const [now_pv_id, setPVID] = useState([]);
    const [now_ap_id, setAPID] = useState([]);

    const [fullPath, setFullPath] = useState('https://pm25.gistda.or.th');

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const [headCells, setHeadCells] = useState([
        {
            id: 'p_name',
            numeric: false,
            disablePadding: false,
            label: 'จังหวัด',
        },
        {
            id: 'p_PM25',
            numeric: true,
            disablePadding: false,
            label: 'ชั่วโมงล่าสุด (µg/m³)',
        },
        {
            id: 'p1',
            numeric: true,
            disablePadding: false,
            label: `คาดการณ์รายชั่วโมงถัดไป`,
        },
        {
            id: 'p_24PM25',
            numeric: true,
            disablePadding: false,
            label: 'เฉลี่ย 24 ชั่วโมง (µg/m³)',
        },
        {
            id: 'detail',
            numeric: true,
            disablePadding: false,
            label: 'รายอำเภอ',
        },
        {
            id: 'chart',
            numeric: true,
            disablePadding: false,
            label: 'ข้อมูลกราฟ',
        },
    ]);

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {/* <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell> */}
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    // function EnhancedTableToolbar(props) {
    //     const { numSelected } = props;
    //     return (
    //         <Toolbar
    //             sx={[
    //                 {
    //                     pl: { sm: 2 },
    //                     pr: { xs: 1, sm: 1 },
    //                 },
    //                 numSelected > 0 && {
    //                     bgcolor: (theme) =>
    //                         alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
    //                 },
    //             ]}
    //         >
    //             {numSelected > 0 ? (
    //                 <Typography
    //                     sx={{ flex: '1 1 100%' }}
    //                     color="inherit"
    //                     variant="subtitle1"
    //                     component="div"
    //                 >
    //                     {numSelected} selected
    //                 </Typography>
    //             ) : (
    //                 <Typography
    //                     sx={{ flex: '1 1 100%' }}
    //                     variant="h6"
    //                     id="tableTitle"
    //                     component="div"
    //                 >
    //                     Nutrition
    //                 </Typography>
    //             )}
    //             {numSelected > 0 ? (
    //                 <Tooltip title="Delete">
    //                     <IconButton>
    //                         <DeleteIcon />
    //                     </IconButton>
    //                 </Tooltip>
    //             ) : (
    //                 <Tooltip title="Filter list">
    //                     <IconButton>
    //                         <FilterListIcon />
    //                     </IconButton>
    //                 </Tooltip>
    //             )}
    //         </Toolbar>
    //     );
    // }

    // EnhancedTableToolbar.propTypes = {
    //     numSelected: PropTypes.number.isRequired,
    // };

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('p_name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(77);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = fetchedData.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            [...fetchedData]
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage],
    );

    // const [visibleRows, setVisibleRows] = React.useState([]);

    // const handleChangeVisible = () => {
    //     setVisibleRows(
    //         () =>
    //             [...fetchedData]
    //                 .sort(getComparator(order, orderBy))
    //                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    //         [order, orderBy, page, rowsPerPage],
    //     );
    // }

    // function setPVID(i) {
    //     now_pv_id = i;
    // }

    const handleChange = () => {

        setsBasemap(sBasemap === 1 ? 0 : 1);
        // console.log(basemap);
        setBasemap(sBasemap === 1 ? 0 : 1);

        // toggleBasemap(basemap);
    };

    const handleOpen = (id, level, name) => {
        setIsLoading24(true);
        getData24(id, level);

        var sid = id.toString();
        var bkk = sid.slice(0, 2) === '10'

        var lv = "";
        if (level === "Province") {
            bkk ? lv = "" : lv = "จังหวัด";
        } else if (level === "Amphoe") {
            bkk ? lv = "เขต" : lv = "อำเภอ";
        } else if (level === "Tambon") {
            bkk ? lv = "แขวง" : lv = "ตำบล";
        }
        // setModalName(lv + name);
        setModalName(lv + name);
    };

    const handleOpen1 = () => {
        setOpen1(true);
    }
    const handleOpenX = () => {
        setOpenX(true);
    }
    const handleOpenI = () => {
        setOpenI(true);
    }
    const handleOpenA = (id, name) => {
        setOpenA(true);
        getDataAmphoe(id);

        setModalNameA(id.toString().slice(0, 2) === "10" ? "ข้อมูลรายเขตของ" + name : "ข้อมูลรายอำเภอของจังหวัด" + name);
    }
    const handleOpenT = (id, name) => {
        setOpenT(true);
        getDataTambon(id);

        setModalNameT(id.toString().slice(0, 2) === "10" ? "ข้อมูลรายแขวงของเขต" + name : "ข้อมูลรายตำบลของอำเภอ" + name);
    }

    const getUrl = async () => {
        setIsLoadingMap(true);
        const response = await fetch(
            fullPath + "/rest/getUrl"
        );
        const result = await response.json();

        setUrlPM25(await result.data.find(obj => obj.wms === "pm25").url);
        setUrlPM2524(await result.data.find(obj => obj.wms === "pm25_24hrs").url);
        setUrlProvince(await result.data.find(obj => obj.wms === "province").url);
        setUrlHotspot(await result.data.find(obj => obj.wms === "hotspot").url);
        setIsLoadingMap(false);

    };

    const getData = async () => {
        const response = await fetch(
            fullPath + "/rest/getPm25byProvincePred3"
            // "http://127.0.0.1:5000/rest/getPm25byProvincePred3"
        );
        const result = await response.json();

        const timeString = result.data[0].dt_predict;
        const dateTime = new Date(timeString);
        dateTime.setHours(dateTime.getHours() - 7);
        const newTimeString0 = dateTime.toTimeString().split(":")[0] + ":00"; // "01:00"
        dateTime.setHours(dateTime.getHours() + 1);
        const newTimeString1 = dateTime.toTimeString().split(":")[0] + ":00"; // "01:00"
        dateTime.setHours(dateTime.getHours() + 1);
        const newTimeString2 = dateTime.toTimeString().split(":")[0] + ":00"; // "02:00"
        dateTime.setHours(dateTime.getHours() + 1);
        const newTimeString3 = dateTime.toTimeString().split(":")[0] + ":00"; // "03:00"

        setLastDt(result.datetimeThai);
        setPredDt([newTimeString0, newTimeString1, newTimeString2, newTimeString3]);

        setFetchedData(
            result.data.map((province) => ({
                p_name: province.pv_tn,
                p_PM25: parseFloat(parseFloat(province.pm25).toFixed(1)),
                p_24PM25: parseFloat(parseFloat(province.pm25Avg24hr).toFixed(1)),
                p_id: province.pv_idn,
                p1: parseFloat(parseFloat(province.pred1).toFixed(1)),
                p2: parseFloat(parseFloat(province.pred2).toFixed(1)),
                p3: parseFloat(parseFloat(province.pred3).toFixed(1)),
                // p_datetime: province.dt.split("T")[0].concat(" ", province.dt.split("T")[1].replace(":00.000Z", "")).replaceAll("-", "/")
            }))
        );


        setIsLoading(false);
        // console.log(fetchedData);
        // setDateTime(fetchedData[0].province_data.p_datetime);
        // setExpanded([...Array(result.data.length)].map((val) => false));
    };

    const getDataAmphoe = async (id) => {
        // console.log(id);
        setIsLoadingAmphoe(true);
        const response = await fetch(
            fullPath + "/rest/getPm25byAmphoePred3?pv_idn=" + id
        );
        const result = await response.json();
        // console.log(result1)

        setFetchedDataAmphoe(
            result.data.map((amphoe) => ({
                a_name: amphoe.ap_tn,
                a_PM25: parseFloat(amphoe.pm25).toFixed(1),
                a_24PM25: parseFloat(amphoe.pm25Avg24hr).toFixed(1),
                a_id: amphoe.ap_idn,
                p1: parseFloat(amphoe.pred1).toFixed(1),
                p2: parseFloat(amphoe.pred2).toFixed(1),
                p3: parseFloat(amphoe.pred3).toFixed(1),
                a_datetime: amphoe.dt.split("T")[0].concat(" ", amphoe.dt.split("T")[1].replace(":00.000Z", "")).replaceAll("-", "/")

            }))
        );
        setIsLoadingAmphoe(false);
    };

    const getDataTambon = async (id) => {
        // console.log(id);
        setIsLoadingTambon(true);
        const response = await fetch(
            fullPath + "/rest/getPm25byTambonPred3?ap_idn=" + id
        );
        const result = await response.json();

        setFetchedDataTambon(
            result.data.map((tambon) => ({
                t_name: tambon.tb_tn,
                t_PM25: parseFloat(tambon.pm25).toFixed(1),
                t_24PM25: parseFloat(tambon.pm25Avg24hr).toFixed(1),
                t_id: tambon.tb_idn,
                p1: parseFloat(tambon.pred1).toFixed(1),
                p2: parseFloat(tambon.pred2).toFixed(1),
                p3: parseFloat(tambon.pred3).toFixed(1),
                t_datetime: tambon.dt.split("T")[0].concat(" ", tambon.dt.split("T")[1].replace(":00.000Z", "")).replaceAll("-", "/")
            }))
        );
        setIsLoadingTambon(false);
    };


    const getData24 = async (id, level) => {

        var lv_idn = "";
        if (level === "Province") {
            lv_idn = "pv";
        } else if (level === "Amphoe") {
            lv_idn = "ap";
        } else if (level === "Tambon") {
            lv_idn = "tb";
        }

        setOpen(true);
        const response1 = await fetch(
            fullPath + "/rest/getPM25by" + level + "24hrs?" + lv_idn + "_idn=" + id
        );
        const result1 = await response1.json();

        const r1 = result1.graphHistory24hrs;


        var alldata = []; //อาจต้องเปลี่ยนเป็น setState
        var alltime = []; //อาจต้องเปลี่ยนเป็น setState

        let n, m = 0;

        for (var i = 0; i < r1.length; i++) {
            // { y: parseInt(Data[i]), color: '#FF0000' }
            let thisValue = parseFloat(r1[i][0].toFixed(1));
            let thisColor = '#FFF';

            if (thisValue <= 15.0) {
                thisColor = "#3BCCFF";
            } else if (thisValue <= 25.0) {
                thisColor = "#92D050";
            } else if (thisValue <= 37.5) {
                thisColor = "#FFFF00";
            } else if (thisValue <= 75.0) {
                thisColor = "#FFA200";
            } else {
                thisColor = "#FF3B3B";
            }
            alldata.push({ y: thisValue, color: thisColor });

            alltime.push(r1[i][1].split("T")[1].replace(":00.000Z", " น."));
            n++;
        }

        const response2 = await fetch(
            fullPath + "/rest/pred/getPM25by" + level + "?" + lv_idn + "_idn=" + id
        );
        const result2 = await response2.json();

        const r2 = result2.graphPredictByHrs;

        for (var i = 0; i < r2.length; i++) {
            // { y: parseInt(Data[i]), color: '#FF0000' }
            let thisValue = parseFloat(r2[i][0].toFixed(1));
            let thisColor = '#FFF';

            if (thisValue <= 15.0) {
                thisColor = "#3BCCFF";
            } else if (thisValue <= 25.0) {
                thisColor = "#92D050";
            } else if (thisValue <= 37.5) {
                thisColor = "#FFFF00";
            } else if (thisValue <= 75.0) {
                thisColor = "#FFA200";
            } else {
                thisColor = "#FF3B3B";
            }
            alldata.push({ y: thisValue, color: thisColor });

            alltime.push(`(คาดการณ์) ${r2[i][1].split("T")[1].replace(":00.000Z", " น.")}`);
            m++;
        }

        setAllData(alldata);
        setAllTime(alltime);

        setcData([n, m]);

        console.log(alldata);

        setIsLoading24(false);
    };

    const options1 = {
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        yAxis: {
            title: {
                text: 'PM 2.5 (µg/m³)'
            },
            min: 0
        },
        xAxis: {
            categories: allTime,
            plotBands: [
                { from: allTime.length - cData[1] - 0.5, to: allTime.length - 0.5, color: '#EEE' },
            ],
        },

        // legend: {
        //   layout: 'vertical',
        //   align: 'center',
        //   verticalAlign: 'top'
        // },
        chart: {
            type: 'column',
            // zoomType: 'x',
            // panning: true,
            // panKey: 'shift'
        },
        plotOptions: {
            // spline: {
            //     marker: {
            //         radius: 4,
            //         lineColor: '#666666',
            //         lineWidth: 1
            //     }
            // },
            series: {
                borderRadius: {
                    radius: 3
                },
                borderWidth: 0.25,
                // pointWidth: 15
            },
        },
        series: [{
            name: 'PM 2.5 (µg/m³)',
            data: allData,
            borderColor: '#000',
            // color: '#ffa500'
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 350
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }

    }


    // const [data, setdata] = useState();
    // const options = {};


    // const handleClick = (index, id) => {
    //     setExpanded(
    //         expanded.map((boolean_value, i) => {

    //             if (index === i) {
    //                 // once we retrieve the collapse index, we negate it
    //                 if (!boolean_value) {
    //                     setPVID(id);
    //                     getDataAmphoe();
    //                     // setIsLoadingAmphoe(true);
    //                 }
    //                 return !boolean_value;
    //             } else {
    //                 // all other collapse will be closed
    //                 return false;
    //             }
    //         })
    //     );

    // };

    const props = {
        request: "GetMap",
        service: "WMTS",
        version: "1.3",
        format: "image/png",
        layers: "0",
        transparent: true,
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            borderRadius: (props) => {
                return "4px";
            },
            paddingTop: (props) => {
                return "4px";
            },
            paddingBottom: (props) => {
                return "4px";
            },
            paddingLeft: (props) => {
                return "4px";
            },
            paddingRight: (props) => {
                return "4px";
            },
            width: (props) => {
                return "60px";
            },
            textAlign: (props) => {
                return "center";
            },
            background: (props) => {
                if (props.value <= 15.0) {
                    return "#3BCCFF";
                } else if (props.value > 15.0 && props.value <= 25.0) {
                    return "#92D050";
                } else if (props.value > 25.0 && props.value <= 37.5) {
                    return "#FFFF00";
                } else if (props.value > 37.5 && props.value <= 75.0) {
                    return "#FFA200";
                } else {
                    return "#FF3B3B";
                }
            },
        },
        openButton: {
            color: '#fff',
        }
    }));
    const StyledTableCell = (props) => {
        const classes = useStyles(props);
        return (
            <TableCell
                classes={{ root: classes.root }}
            >
                {props.children}
            </TableCell>
        );
    };

    useEffect(() => {
        // handleOpenX();
        setIsLoadingMap(true);
        setIsLoading(true);
        setIsLoadingAmphoe(true);
        setIsLoadingTambon(true);
        getData();
        // getUrl();

        setOpenHotspot(true);
        setOpenProvince(false);

        setBasemap(1);
        setsBasemap(1);

        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);

        if (!urlPM25) return;


        // //-- sphere --//
        // sphere = window.sphere;
        // map = new sphere.Map({ placeholder: document.getElementById('map-sphere'), zoomRange: { min: 0, max: 12 }, });
        // map._this = this;

        // map.Event.bind("ready", function () {
        //     map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
        //     map.Ui.DPad.visible(false);
        //     map.Ui.LayerSelector.visible(false);
        //     // map.Ui.Geolocation.visible(false);
        //     // map.Ui.Zoombar.visible(false);

        //     // let layer_pm25 = new sphere.Layer('pm25:pm25_hourly_raster', {
        //     let layer_24pm25 = new sphere.Layer('0', {
        //         type: sphere.LayerType.WMS,
        //         url: urlPM2524,
        //         zoomRange: { min: 1, max: 12 },
        //         zIndex: 5,
        //         opacity: 0.8,
        //         id: 'layer_24pm25'
        //     });

        //     let province = new sphere.Layer('0', {
        //         type: sphere.LayerType.WMS,
        //         url: urlProvince,
        //         zoomRange: { min: 1, max: 12 },
        //         zIndex: 6,
        //         opacity: 1,
        //         id: 'province'
        //     });

        //     // let layer_24pm25 = new sphere.Layer('0', {
        //     //     type: sphere.LayerType.WMS,
        //     //     url: "https://gistdaportal.gistda.or.th/data/services/pm_check/pm25_hourly_raster_24hr/MapServer/WMSServer",
        //     //     zoomRange: { min: 1, max: 12 },
        //     //     zIndex: 5,
        //     //     opacity: 0.8,
        //     //     id: 'layer_24pm25'
        //     // });

        //     // setTimeout(() => {
        //     map.Layers.add(layer_24pm25);
        //     map.Layers.add(province);
        //     console.log('add');
        //     // }, 1000);


        //     // map.Layers.add(hotspot);
        // });
        setIsLoadingMap(false);

        // map.Event.bind(sphere.EventName.Click, function (location) {
        //     // console.log(location);
        //     // getDataLocation(location.lon, location.lat);
        //     map.mouseLocation = location;
        // });

        const interval = setInterval(() => {
            // setDateTime(new Date());
            if (0) {
                reloadData();
                goHome();
            }
        }, 600000); //set your time here. repeat every 10 mins

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            clearInterval(interval);
        };
    }, []);

    const reloadData = async () => {
        setIsLoading(true);
        getData();
        // console.log([...fetchedData]
        //     .sort(getComparator(order, orderBy))
        //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
        // console.log(fetchedData);
    };

    const goHome = async () => {
        map.bound({ minLon: 97.343699, minLat: 5.612738, maxLon: 105.636781, maxLat: 20.464926 }, { padding: { top: 7, bottom: 7 } });
    }

    const toggleBasemap = async (i) => {
        setBasemap(sBasemap);
        var url = [
            urlPM2524,
            urlPM25

            // "https://tile.gistda.or.th/geoserver/pm25/wms",
            // "https://tile.gistda.or.th/geoserver/pm25/wms"
        ]

        // console.log(url[basemap]);

        // // let layer_pm25 = new sphere.Layer('pm25:pm25_hourly_raster', {
        // let layer_pm25 = new sphere.Layer('0', {
        //     type: sphere.LayerType.WMS,
        //     url: urlPM25,
        //     zoomRange: { min: 1, max: 12 },
        //     zIndex: 5,
        //     opacity: 0.8,
        //     id: 'layer_pm25'
        // });

        // // let layer_24pm25 = new sphere.Layer('pm25:pm25_hourly_raster_24hr', {
        // let layer_24pm25 = new sphere.Layer('0', {
        //     type: sphere.LayerType.WMS,
        //     url: urlPM2524,
        //     zoomRange: { min: 1, max: 12 },
        //     zIndex: 5,
        //     opacity: 0.8,
        //     id: 'layer_24pm25'
        // });

        // { i === 1 ? map.Layers.add(layer_pm25) : map.Layers.remove(layer_pm25) }
        // { i === 0 ? map.Layers.add(layer_24pm25) : map.Layers.remove(layer_24pm25) }

    }

    const removeHotspot = async () => {
        // let hotspot = new sphere.Layer(['0', '1'], {
        //     type: sphere.LayerType.WMS,
        //     url: urlHotspot,
        //     zoomRange: { min: 1, max: 12 },
        //     zIndex: 10,
        //     opacity: 0.6,
        //     id: 'hotspot'
        // });

        // { openHotspot ? map.Layers.add(hotspot) : map.Layers.remove(hotspot) }
        setOpenHotspot(!openHotspot);
    }

    // const removeProvine = async () => {
    //     let province = new sphere.Layer('0', {
    //         type: sphere.LayerType.WMS,
    //         url: "https://gistdaportal.gistda.or.th/data2/services/L05_Province_GISTDA_50k_nonlabel/MapServer/WMSServer",
    //         zoomRange: { min: 1, max: 12 },
    //         zIndex: 6,
    //         opacity: 1,
    //         id: 'province'
    //     });

    //     { openProvince ? map.Layers.add(province) : map.Layers.remove(province) }
    //     setOpenProvince(!openProvince);
    // }

    // const getDataLocation = async (lon, lat) => {
    //     const response = await fetch(
    //         "https://pm25-cloud-run-anqwcyx7xa-as.a.run.app/rest/getPm25byLocation?lat=" + lat + "&lng=" + lon
    //     );
    //     const result = await response.json();



    //     setFetchedDataLoc(
    //         result.data.map((data) => ({
    //             data: {
    //                 d_name: data.loc.loctext,
    //                 d_PM25: parseFloat(data.pm25).toFixed(1),
    //                 d_24PM25: parseFloat(data.pm25Avg24hr).toFixed(1),
    //                 d_datetime: data.datetimeThai.dateThai.concat(" ", data.datetimeThai.timeThai)
    //             }
    //         }))
    //     );

    //     console.log(fetchedDataLoc);

    //     setIsLoading(false);



    //     // map.Event.bind(sphere.EventName.Click, function(location) {
    //     //     console.log(location);
    //     //     map.mouseLocation = location;
    //     //   });
    // }



    return (
        <div className="App" style={{ marginTop: 60 }}>
            {/* <ThemeProvider theme={light ? themeLight : themeDark}> */}

            {/* <h2>Height: {windowSize[1]}</h2> */}
            <Grid container spacing={{ xs: 1 }} >
                <Grid item xs={12}>
                    <Item>
                        <Grid item xs={1}>
                            <Item></Item>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: "14px" }} >
                            <a href="https://www.mhesi.go.th/"><img src={mhesiPic} height={45} alt="เว็บไซต์กระทรวง อว."></img></a>
                            <a href="https://www.gistda.or.th/"><img src={gistdaPic} height={45} alt="เว็บไซต์ GISTDA"></img></a>
                            <a href="https://www.nrct.go.th/"><img src={nrctPic} height={45} alt="เว็บไซต์ วช."></img></a>
                            <a href="https://pmu-hr.or.th/"><img src={PMUBPic} height={45} alt="เว็บไซต์ บคพ."></img></a>

                            {/* <a href="https://www.pcd.go.th/"><img src={pcdPic} height={45}></img></a> */}
                            {/* <a href="https://www.cmuccdc.org/"><img src={ccdcPic} height={45}></img></a> */}
                            {/* <a href="https://www.ku.ac.th/"><img src={kuPic} height={45}></img></a> */}
                        </Grid>
                        <Grid item xs={12} >
                            <Item><h1>ระบบติดตาม PM2.5 จากเทคโนโลยีดาวเทียมและภูมิสารสนเทศ
                            </h1></Item>
                        </Grid>
                        <Button onClick={() => handleOpen1()} variant="outlined">
                            ติดตั้งแอปพลิเคชัน "เช็คฝุ่น"
                        </Button>
                    </Item>
                </Grid>


                <Grid item xs={1}>
                    <Item></Item>
                </Grid>

                <Grid item xs={10} md={5}>
                    <div style={{ fontSize: "24px", marginBottom: "7px" }}>คำอธิบายความหมาย
                        <IconButton onClick={handleOpenI}>
                            <InfoIcon style={{ color: 'black' }} />
                        </IconButton>
                    </div>


                    {/* <div style={{ fontSize: "12px", marginBottom: "1px" }}></div> */}
                    <Box sx={{ overflow: 'hidden' }}>
                        <TableContainer
                            component={Paper}
                            style={{ maxHeight: 780, width: "100%" }}
                            sx={{
                                marginBottom: 0,
                                "&::-webkit-scrollbar": {
                                    width: 8,
                                    height: 8
                                },
                                "&::-webkit-scrollbar-track": {
                                    backgroundColor: "#e2f1ff"
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#0080ff",
                                    borderRadius: 3
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#1c5bd8"
                                }
                            }}
                        >
                            <Table size="small" aria-label="a dense table" width='100%'>
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            sx={{ backgroundColor: "#3BCCFF" }}>
                                            <div className="pm25-class">
                                                ดีมาก
                                            </div>
                                            <div className="pm25-class">
                                                0-15.0 µg/m³
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            sx={{ backgroundColor: "#92D050" }}>
                                            <div className="pm25-class">
                                                ดี
                                            </div>
                                            <div className="pm25-class">
                                                15.1-25.0 µg/m³
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            sx={{ backgroundColor: "#FFFF00" }}>
                                            <div className="pm25-class">
                                                ปานกลาง
                                            </div>
                                            <div className="pm25-class">
                                                25.1-37.5 µg/m³
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            sx={{ backgroundColor: "#FFA200" }}>
                                            <div className="pm25-class">
                                                เริ่มมีผลต่อสุขภาพ
                                            </div>
                                            <div className="pm25-class">
                                                37.6-75.0 µg/m³
                                            </div>

                                        </TableCell>
                                        <TableCell
                                            sx={{ backgroundColor: "#FF3B3B" }}>
                                            <div className="pm25-class">
                                                มีผลต่อสุขภาพ
                                            </div>
                                            <div className="pm25-class">
                                                &#62; 75.1 µg/m³
                                            </div>

                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Grid item>

                        <Box>
                            <MapContainer center={[13.15, 101]} zoom={6} id='leaflet-container' >
                                {/* <Fab size='small' sx={{
                                    width: '29px',
                                    height: '25px',
                                    minHeight: 'auto',
                                    position: 'absolute',
                                    top: 144,
                                    left: 10,
                                    borderRadius: 1,
                                    color: '#21367C',
                                    backgroundColor: '#FFF',
                                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                                    boxShadow: 0,
                                }}>
                                    <HomeIcon
                                        onClick={() => goHome()} />
                                </Fab> */}

                                <Fab size='small' sx={{
                                    width: '250px',
                                    height: '25px',
                                    minHeight: 'auto',
                                    position: 'absolute',
                                    bottom: 40,
                                    right: 10,
                                    borderRadius: 1,
                                    color: '#21367C',
                                    backgroundColor: '#FFF',
                                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                                    boxShadow: 0,
                                    fontSize: 12,
                                }}>
                                    {basemap === 1 ? `ข้อมูล PM2.5 เฉลี่ย 24 ชั่วโมง ${isLoading ? '' : `ณ ${lastDt.timeThai}`}` : `ข้อมูล PM2.5 รายชั่วโมง ${isLoading ? '' : `ณ ${lastDt.timeThai}`}`}
                                </Fab>

                                {openHotspot ? '' : <Fab size='small' sx={{
                                    width: '250px',
                                    height: '25px',
                                    minHeight: 'auto',
                                    position: 'absolute',
                                    bottom: 70,
                                    right: 10,
                                    borderRadius: 1,
                                    color: '#21367C',
                                    backgroundColor: '#FFF',
                                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                                    boxShadow: 0,
                                    fontSize: 12,
                                }}>
                                    🔴 จุดความร้อนสะสม 24 ชั่วโมง (ระบบ VIIRS)
                                </Fab>}

                                <Fab size='small' aria-label="เปิด/ปิดชั้นข้อมูลแสดงแผนที่จุดความร้อน" sx={{
                                    width: '29px',
                                    height: '25px',
                                    minHeight: 'auto',
                                    position: 'absolute',
                                    top: 48,
                                    right: 10,
                                    borderRadius: 1,
                                    color: '#21367C',
                                    backgroundColor: '#FFF',
                                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                                    boxShadow: 0,

                                }}>
                                    <Tooltip title={"จุดความร้อน"}>
                                        <LocalFireDepartmentIcon
                                            color={openHotspot ? "primary" : "secondary"} variant="contained" onClick={removeHotspot} />
                                    </Tooltip>
                                </Fab>

                                <Fab size='small' aria-label="สลับชั้นข้อมูลแสดงแผนที่ข้อมูลPM2.5เฉลี่ยรายวัน/รายชั่วโมง" sx={{
                                    width: '29px',
                                    height: '25px',
                                    minHeight: 'auto',
                                    position: 'absolute',
                                    top: 78,
                                    right: 10,
                                    borderRadius: 1,
                                    color: '#21367C',
                                    backgroundColor: '#FFF',
                                    outline: '2px solid rgba(0, 0, 0, 0.1)',
                                    boxShadow: 0,

                                }}>
                                    <Tooltip title={"ชั้นข้อมูล PM2.5"}>
                                        <LayersIcon
                                            color={basemap === 1 ? "primary" : "secondary"} variant="contained" onClick={handleChange} />
                                    </Tooltip>
                                </Fab>

                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />

                                {!openHotspot ?
                                    <WMSTileLayer
                                        zIndex={3}
                                        layers={['0', '1']}
                                        url={urlHotspot}
                                        maxZoom={12}
                                        transparent={true}
                                        format='image/png'
                                        opacity={0.8}
                                    />
                                    :
                                    ''
                                }
                                {basemap === 0 ?
                                    <WMSTileLayer
                                        zIndex={1}
                                        layers={['0']}
                                        url={urlPM25}
                                        maxZoom={12}
                                        transparent={true}
                                        format='image/png'
                                        opacity={0.8}
                                    />
                                    :
                                    ''
                                }
                                {basemap === 1 ?
                                    <WMSTileLayer
                                        zIndex={1}
                                        layers={['0']}
                                        url={urlPM2524}
                                        maxZoom={12}
                                        transparent={true}
                                        format='image/png'
                                        opacity={0.8}
                                    />
                                    :
                                    ''
                                }
                                <WMSTileLayer
                                    zIndex={2}
                                    layers={['0']}
                                    url={urlProvince}
                                    maxZoom={12}
                                    transparent={true}
                                    format='image/png'
                                    opacity={1}
                                />

                            </MapContainer>
                        </Box>


                    </Grid>

                    <Grid item xs={1} md={0}>

                    </Grid>

                </Grid>

                <Grid item xs={12} md={5}>
                    <Stack spacing={1} padding={1} direction="row">
                        {/* <Button color={openHotspot ? "primary" : "secondary"} variant="contained" onClick={removeHotspot}>{openHotspot ? "เปิด" : "ปิด"}จุดความร้อนสะสม 24 ชม (ระบบ VIIRS)</Button> */}
                        {/* <Button color={openProvince ? "primary" : "secondary"} variant="contained" onClick={removeProvine}>{openProvince ? "เปิด" : "ปิด"}ชั้นข้อมูลขอบเขตจังหวัด</Button> */}
                        {/* <Button color="primary" variant="contained" onClick={toggleBasemap}>ข้อมูลราย{basemap === 0 ? "" : "24"}ชั่วโมง</Button> */}
                        {/* <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">แผนที่</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sBasemap}
                                    label="แผนที่"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>รายชั่วโมง</MenuItem>
                                    <MenuItem value={1}>เฉลี่ย 24 ชั่วโมง</MenuItem>
                                </Select>
                            </FormControl>
                        </Box> */}
                    </Stack>

                    <Stack padding={1}>

                        <div>
                            {isLoading ? `Loading...` : `ข้อมูลล่าสุด ณ ${lastDt.dateThai} ${lastDt.timeThai}`} {isLoading ? '' :
                                <IconButton aria-label='อัพเดทข้อมูลล่าสุด' onClick={() => { reloadData(true); }}>
                                    <RestartAltIcon />
                                </IconButton>}
                            {/* {isLoading ? `` : <FormControlLabel control={<Switch size="small"/>} label="Auto Reload" />} */}
                        </div>
                        {/* <div>
                            {isLoading ? '' : `คาดการณ์ ณ ${predDt[0]}`}
                        </div> */}
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                                <TableContainer sx={{
                                    maxHeight: 730,
                                    "&::-webkit-scrollbar": {
                                        width: 5,
                                        height: 5
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: "#e2f1ff"
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#0080ff",
                                        borderRadius: 2
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#1c5bd8"
                                    }
                                }}>
                                    <Table stickyHeader
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="PM2.5 Table"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={fetchedData.length}
                                        >
                                            {/* <TableRow>

                                                <TableCell component="th" sx={{ width: 120 }}>จังหวัด</TableCell>
                                                <TableCell component="th" sx={{ width: 120 }} align="center"> <div>ชั่วโมงล่าสุด<br />(µg/m³)</div> </TableCell>
                                                <TableCell component="th" sx={{ width: 120 }} align="center"> <div>คาดการณ์<br />{isLoading ? '' : `ณ ${predDt[0]}`}<br />{isLoading ? '' : `${fetchedData.length > 0 && fetchedData[0].p1 * 0 === 0 ? `${predDt[1]} |` : ''} ${fetchedData.length > 0 && fetchedData[0].p2 * 0 === 0 ? `${predDt[2]} |` : ''} ${fetchedData.length > 0 && fetchedData[0].p3 * 0 === 0 ? `${predDt[3]}` : ''} `}</div> </TableCell>
                                                <TableCell component="th" sx={{ width: 120 }} align="center"> <div>เฉลี่ย<br />24 ชั่วโมง<br />(µg/m³)</div> </TableCell>
                                                <TableCell component="th" sx={{ width: 30 }} align="center">รายอำเภอ</TableCell>
                                                <TableCell component="th" sx={{ width: 30 }} align="center"> <div>ข้อมูล<br />กราฟ</div> </TableCell>
                                            </TableRow> */}
                                        </EnhancedTableHead>

                                        <TableBody>
                                            {isLoading ? <TableRow>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                            </TableRow>
                                                :
                                                ([...fetchedData]
                                                    .sort(getComparator(order, orderBy))
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((row, index) => {
                                                        // const isItemSelected = selected.includes(row.id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <StyledTableRow
                                                                // hover
                                                                // onClick={(event) => handleClick(event, row.id)}
                                                                // role="checkbox"
                                                                // aria-checked={isItemSelected}
                                                                // tabIndex={-1}
                                                                key={row.id}
                                                            // selected={isItemSelected}
                                                            // sx={{ cursor: 'pointer' }}
                                                            >
                                                                {/* <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                // checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell> */}
                                                                <TableCell
                                                                    component="td"
                                                                    // id={labelId}
                                                                    scope="row"
                                                                    padding="1"
                                                                >
                                                                    {row.p_name}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Stack width={'100%'} sx={{ justifyContent: 'center' }} direction="row" spacing={0.25}>
                                                                        <StyledTableCell value={row.p_PM25} component="th" align="center">
                                                                            {row.p_PM25}
                                                                        </StyledTableCell>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Stack width={'100%'} sx={{ justifyContent: 'center' }} direction="row" spacing={0.25}>
                                                                        {row.p1 * 0 === 0 ?
                                                                            <StyledTableCell value={row.p1} component="th" align="center">
                                                                                {(row.p1 === row.p_PM25 ? '' : (parseFloat(row.p1) > parseFloat(row.p_PM25) ? '' : '')) + row.p1}
                                                                            </StyledTableCell>
                                                                            : ''}
                                                                        {row.p2 * 0 === 0 ?
                                                                            <StyledTableCell value={row.p2} component="th" align="center">
                                                                                {(row.p2 === row.p1 ? '' : (parseFloat(row.p2) > parseFloat(row.p1) ? '' : '')) + row.p2}
                                                                            </StyledTableCell>
                                                                            : ''}
                                                                        {row.p3 * 0 === 0 ?
                                                                            <StyledTableCell value={row.p3} component="th" align="center">
                                                                                {(row.p3 === row.p2 ? '' : (parseFloat(row.p3) > parseFloat(row.p2) ? '' : '')) + row.p3}
                                                                            </StyledTableCell>
                                                                            : ''}
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Stack width={'100%'} sx={{ justifyContent: 'center' }} direction="row" spacing={0.25}>
                                                                        <StyledTableCell value={row.p_24PM25} component="th" align="center">
                                                                            {row.p_24PM25}
                                                                        </StyledTableCell>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell component="td" align="center">
                                                                    {/* <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => handleClick(index, province.province_data.p_id)}

                                                    >
                                                        {expanded[index] ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </IconButton> */}
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() => handleOpenA(row.p_id, row.p_name)}
                                                                    ><FormatListNumberedIcon /></IconButton>
                                                                </TableCell>

                                                                <TableCell component="td" scope="row" align="center">
                                                                    <div>
                                                                        <Button aria-label="แสดงกราฟข้อมูลย้อนหลังรายชั่วโมง" onClick={() => handleOpen(row.p_id, 'Province', row.p_name)}>
                                                                            <ShowChartIcon></ShowChartIcon>
                                                                        </Button>
                                                                    </div>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: (dense ? 33 : 53) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={fetchedData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}
                            </Paper>
                            {/* <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense padding"
                            /> */}
                        </Box>

                    </Stack>
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: "7px" }}>
                    {/* เว็บไซต์เวอร์ชันเก่า: <a target="_blank" href="http://103.156.151.128/pm25">คลิกที่นี่</a> */}
                    ติดต่อเรา Email: innotech@gistda.or.th / Facebook: <a target="_blank" href="https://www.facebook.com/gistda">GISTDA</a>
                </Grid>

            </Grid>

            <Modal
                open={openX}
                onClose={handleCloseX}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        เรียนผู้ใช้งาน
                        เนื่องด้วยมีการงดจ่ายไฟฟ้าในวันที่ 21-22 ธันวาคม 2567 ซึ่งจะส่งผลต่อการอัปเดทข้อมูลในระบบ เช็คฝุ่น โดยข้อมูลในช่วงวันและเวลาดังกล่าวจะไม่สามารถอัปเดทข้อมูลเป็นปัจจุบันได้ จึงเรียนมาเพื่อทราบและขออภัยในความไม่สะดวกมา ณ โอกาสนี้
                    </Typography>
                    <Button onClick={handleCloseX}>ปิด</Button>
                </Box>
            </Modal>

            <Modal
                open={openI}
                onClose={handleCloseI}
                aria-labelledby="คำอธิบาย"
                aria-describedby="คำอธิบาย"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="body1" >
                        ข้อมูล PM2.5 (เช็คฝุ่น) วิเคราะห์ด้วยเทคโนโลยีปัญญาประดิษฐ์ (AI) เป็นการบูรณาการข้อมูลจากดาวเทียม เช่น Himawari ร่วมกับสถานีตรวจวัด PM 2.5 (กรมควบคุมมลพิษ), ข้อมูลสภาพอากาศ (กรมอุตุนิยมวิทยา) รวมถึงข้อมูลแหล่งกำเนิดฝุ่น เช่น จุดความร้อน เป็นต้น
                    </Typography>
                    <Button onClick={handleCloseI}>ปิด</Button>
                </Box>
            </Modal>

            <Modal
                open={openA}
                onClose={handleCloseA}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>

                    <h2 id="parent-modal-title">{modalNameA}</h2>
                    <Box margin={1}>
                        {/* <Typography gutterBottom component="div">
                            ข้อมูลรายอำเภอของจังหวัด{province.province_data.p_name}
                        </Typography> */}

                        <TableContainer sx={{
                            maxHeight: 510,
                            "&::-webkit-scrollbar": {
                                width: 5,
                                height: 5
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#e2f1ff"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#0080ff",
                                borderRadius: 2
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                background: "#1c5bd8"
                            }
                        }}>
                            <Table stickyHeader size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: 30 }} align="center">ลำดับ</TableCell>
                                        <TableCell sx={{ width: 120 }}>{modalNameA.toString().split('ของ')[0].split('ข้อมูลราย')[1]}</TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"> <div>ชั่วโมงล่าสุด<br />(µg/m³)</div> </TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"> <div>คาดการณ์<br />{isLoadingAmphoe ? '' : `ณ ${predDt[0]}`}<br />{isLoadingAmphoe ? '' : `${fetchedData.length > 0 && fetchedData[0].p1 * 0 === 0 ? `${predDt[1]} |` : ''} ${fetchedData.length > 0 && fetchedData[0].p2 * 0 === 0 ? `${predDt[2]} |` : ''} ${fetchedData.length > 0 && fetchedData[0].p3 * 0 === 0 ? `${predDt[3]}` : ''} `}</div> </TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"> <div>เฉลี่ย<br />24 ชั่วโมง<br />(µg/m³)</div> </TableCell>
                                        <TableCell sx={{ width: 30 }} align="center">{modalNameA.toString().split('ของ')[0].split('ข้อมูลราย')[1] === 'เขต' ? 'รายแขวง' : 'รายตำบล'}</TableCell>
                                        <TableCell sx={{ width: 70 }} align="center"> <div>ข้อมูล<br />กราฟ</div> </TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"><div>ข้อมูลล่าสุด<br />เวลา</div></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {isLoadingAmphoe
                                        ?
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                        </TableRow>

                                        : fetchedDataAmphoe.map((amphoe, index) => (
                                            <StyledTableRow>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell>{amphoe.a_name}</TableCell>
                                                <TableCell component="th" scope="row" align="center">
                                                    <div align='center'>
                                                        <StyledTableCell value={amphoe.a_PM25} component="th" align="center">
                                                            {amphoe.a_PM25}
                                                        </StyledTableCell>
                                                    </div>
                                                </TableCell>

                                                <TableCell component="th" scope="row" align="center" style={{ width: '20%' }} margin='10'>
                                                    <Stack align='center' direction="row" spacing={0.25}>

                                                        {amphoe.p1 * 0 === 0 ?
                                                            <StyledTableCell value={amphoe.p1} component="th" align="center">
                                                                {(amphoe.p1 === amphoe.p_PM25 ? '' : (parseFloat(amphoe.p1) > parseFloat(amphoe.a_PM25) ? '' : '')) + amphoe.p1}
                                                            </StyledTableCell>
                                                            : ''}
                                                        {amphoe.p2 * 0 === 0 ?
                                                            <StyledTableCell value={amphoe.p2} component="th" align="center">
                                                                {(amphoe.p2 === amphoe.p1 ? '' : (parseFloat(amphoe.p2) > parseFloat(amphoe.p1) ? '' : '')) + amphoe.p2}
                                                            </StyledTableCell>
                                                            : ''}
                                                        {amphoe.p3 * 0 === 0 ?
                                                            <StyledTableCell value={amphoe.p3} component="th" align="center">
                                                                {(amphoe.p3 === amphoe.p2 ? '' : (parseFloat(amphoe.p3) > parseFloat(amphoe.p2) ? '' : '')) + amphoe.p3}
                                                            </StyledTableCell>
                                                            : ''}
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" align="center">
                                                    <div align='center'>
                                                        <StyledTableCell value={amphoe.a_24PM25} component="th" align="center">
                                                            {amphoe.a_24PM25}
                                                        </StyledTableCell>
                                                    </div>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => handleOpenT(amphoe.a_id, amphoe.a_name)}>
                                                        <FormatListNumberedIcon />
                                                    </IconButton>
                                                </TableCell>

                                                <TableCell component="th" scope="row" align="center">
                                                    <div>
                                                        <Button onClick={() => handleOpen(amphoe.a_id, 'Amphoe', amphoe.a_name)}>
                                                            <ShowChartIcon></ShowChartIcon>
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">{amphoe.a_datetime}</TableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                    <Button onClick={handleCloseA}>ปิด</Button>
                </Box>
            </Modal>


            <Modal
                open={openT}
                onClose={handleCloseT}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style }}>
                    <h2 id="parent-modal-title">{modalNameT}</h2>
                    <Box margin={1}>
                        {/* <Typography gutterBottom component="div">
                            ข้อมูลรายอำเภอของจังหวัด{province.province_data.p_name}
                        </Typography> */}

                        <TableContainer sx={{
                            maxHeight: 510,
                            "&::-webkit-scrollbar": {
                                width: 5,
                                height: 5
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#e2f1ff"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#0080ff",
                                borderRadius: 2
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                background: "#1c5bd8"
                            }
                        }}>
                            <Table stickyHeader size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: 30 }} align="center">ลำดับ</TableCell>
                                        <TableCell sx={{ width: 120 }}>{modalNameT.toString().split('ของ')[0].split('ข้อมูลราย')[1]}</TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"> <div>ชั่วโมงล่าสุด<br />(µg/m³)</div> </TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"> <div>คาดการณ์<br />{isLoadingTambon ? '' : `ณ ${predDt[0]}`}<br />{isLoadingTambon ? '' : `${fetchedData.length > 0 && fetchedData[0].p1 * 0 === 0 ? `${predDt[1]} |` : ''} ${fetchedData.length > 0 && fetchedData[0].p2 * 0 === 0 ? `${predDt[2]} |` : ''} ${fetchedData.length > 0 && fetchedData[0].p3 * 0 === 0 ? `${predDt[3]}` : ''} `}</div> </TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"> <div>เฉลี่ย 24 ชั่วโมง<br />(µg/m³)</div> </TableCell>
                                        <TableCell sx={{ width: 70 }} align="center"> <div>ข้อมูล<br />กราฟ</div> </TableCell>
                                        <TableCell sx={{ width: 120 }} align="center"><div>ข้อมูลล่าสุด<br />เวลา</div></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {isLoadingTambon
                                        ?
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                        </TableRow>

                                        : fetchedDataTambon.map((tambon, index) => (
                                            <StyledTableRow>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell>{tambon.t_name}</TableCell>
                                                <TableCell component="th" scope="row" align="center">
                                                    <div align='center'>
                                                        <StyledTableCell value={tambon.t_PM25} component="th" align="center">
                                                            {tambon.t_PM25}
                                                        </StyledTableCell>
                                                    </div>
                                                </TableCell>

                                                <TableCell component="th" scope="row" align="center" style={{ width: '20%' }} margin='10'>
                                                    <Stack align='center' direction="row" spacing={0.25}>

                                                        {tambon.p1 * 0 === 0 ?
                                                            <StyledTableCell value={tambon.p1} component="th" align="center">
                                                                {(tambon.p1 === tambon.p_PM25 ? '' : (parseFloat(tambon.p1) > parseFloat(tambon.t_PM25) ? '' : '')) + tambon.p1}
                                                            </StyledTableCell>
                                                            : ''}
                                                        {tambon.p2 * 0 === 0 ?
                                                            <StyledTableCell value={tambon.p2} component="th" align="center">
                                                                {(tambon.p2 === tambon.p1 ? '' : (parseFloat(tambon.p2) > parseFloat(tambon.p1) ? '' : '')) + tambon.p2}
                                                            </StyledTableCell>
                                                            : ''}
                                                        {tambon.p3 * 0 === 0 ?
                                                            <StyledTableCell value={tambon.p3} component="th" align="center">
                                                                {(tambon.p3 === tambon.p2 ? '' : (parseFloat(tambon.p3) > parseFloat(tambon.p2) ? '' : '')) + tambon.p3}
                                                            </StyledTableCell>
                                                            : ''}
                                                    </Stack>
                                                </TableCell>


                                                <TableCell component="th" scope="row" align="center">
                                                    <div align='center'>
                                                        <StyledTableCell value={tambon.t_24PM25} component="th" align="center">
                                                            {tambon.t_24PM25}
                                                        </StyledTableCell>
                                                    </div>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center">
                                                    <div>
                                                        <Button onClick={() => handleOpen(tambon.t_id, 'Tambon', tambon.t_name)}>
                                                            <ShowChartIcon></ShowChartIcon>
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">{tambon.t_datetime}</TableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                    <Button onClick={handleCloseT}>ปิด</Button>
                </Box>
            </Modal>

            <Modal
                open={open1}
                onClose={handleClose1}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        ติดตั้งแอปพลิเคชัน "เช็คฝุ่น"
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={{ xs: 1, md: 2 }}>
                            <Grid item xs={12}>
                                แสกน QR code หรือคลิก
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer
                                    component={Paper}
                                    style={{ maxHeight: 780, width: "100%" }}
                                    sx={{
                                        marginBottom: 0,
                                        "&::-webkit-scrollbar": {
                                            width: 8,
                                            height: 8
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            backgroundColor: "#e2f1ff"
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#0080ff",
                                            borderRadius: 3
                                        },
                                        "&::-webkit-scrollbar-thumb:hover": {
                                            background: "#1c5bd8"
                                        }
                                    }}
                                >
                                    <Table>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <a href="https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B8%9D-%E0%B8%99/id1591356571" target="_blank">
                                                    <img src={aaplPic} height={250} alt="QR code สำหรับดาวน์โหลดแอพพลิเคชันเช็คฝุ่นสำหรับ Apple" ></img>
                                                </a>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <a href="https://play.google.com/store/apps/details?id=com.gistda.pm25check" target="_blank">
                                                    <img src={andPic} height={250} alt="QR code สำหรับดาวน์โหลดแอพพลิเคชันเช็คฝุ่นสำหรับ Android" ></img>
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Button href="https://apps.apple.com/th/app/%E0%B9%80%E0%B8%8A-%E0%B8%84%E0%B8%9D-%E0%B8%99/id1591356571" target="_blank"
                                                    style={{ textTransform: 'none' }}
                                                    variant="contained">iOS</Button>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Button href="https://play.google.com/store/apps/details?id=com.gistda.pm25check" target="_blank"
                                                    style={{ textTransform: 'none' }}
                                                    variant="contained">Android</Button>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>


                            </Grid>
                        </Grid>
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        ข้อมูลย้อนหลัง
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalName}
                        {isLoading24 ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                            :
                            <Box>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options1}
                                />
                            </Box>

                        }
                    </Typography>
                    <Button onClick={handleClose}>ปิด</Button>
                </Box>
            </Modal>
            {/* </ThemeProvider> */}
        </div >
    );
}

export default Home;


// https://webcheck.aaa.in.th/?testId=ArsbrhsXcI3dBBu01WLzkRQCINChtRmJvfN417jFQjp00a0vZq